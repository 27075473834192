import * as KatalMetrics from '@amzn/katal-metrics';
import { KatalMonitoringDriver, KatalMonitoringDriverOptions } from '@amzn/katal-monitoring-aws-driver';


const metricsConsoleErrorHandler = (err: Error) => console.error(err);

// Creating driver
const makeMetricsDriver = (): KatalMonitoringDriver => {


    // ==== Aws Driver ====
    const monitoringConfig: KatalMonitoringDriverOptions = {
        url: "https://metrics.verity-dg.device.finance.amazon.dev/v1/monitoring",
        headers: {
            'Authorization': sessionStorage.getItem('token') || ''
        }
    }
    return new KatalMonitoringDriver(monitoringConfig);
};

// creating Publisher
const makePublisher = (): KatalMetrics.Publisher | undefined => {

    const metricsDriver = makeMetricsDriver();
    const initialMetricsContext = new KatalMetrics.Context.Builder()
        .withSite('DasFin')
        .withServiceName('DasFinVerity')
        .build();

    if (process.env.NODE_ENV === 'test') {
        //  Attach to global window object so tests can see it
        (window as any).metricsDriver = metricsDriver;
    } else {
        return new KatalMetrics.Publisher(
            metricsDriver,
            metricsConsoleErrorHandler,
            initialMetricsContext);
    }
};

export default makePublisher;