import AWS from 'aws-sdk'

export interface AuthConfig {
	IdentityPoolId: string;
	WebSocketUrl: string;
}

export interface StageAuthConfig {
	development: AuthConfig,
	test: AuthConfig,
	prod: AuthConfig
}

const AUTH_CONFIGS: StageAuthConfig = {
	development: {
	    IdentityPoolId: 'us-east-1:cfc7ca59-72a0-4b0a-b829-ceb217be62a3',
	    WebSocketUrl: 'wss://vwq1q573pb.execute-api.us-east-1.amazonaws.com/alpha'
    },
	test: {
	    IdentityPoolId: 'us-east-1:a1fd7d14-ba4f-4db4-8f01-815b0cb1d9a7',
	    WebSocketUrl: 'wss://aguih70pfj.execute-api.us-east-1.amazonaws.com/beta'
	},
	prod: {
	    IdentityPoolId: 'us-east-1:c6e7555b-5184-4c62-90e5-61ede09871fa',
	    WebSocketUrl: 'wss://0ychxob77c.execute-api.us-east-1.amazonaws.com/prod'
	}
}

function getEnv () {
    return (process.env.NODE_ENV as string);
}

// Stage value comes crom website CDK package. Make sure same values are used in each website CDK package
export function getEnvFromUrl () {
    const stage = "prod";
    if (stage.match('beta') != null)
      return 'test';
    if (stage.match('prod') != null)
      return 'prod';
    return 'development';
}

function getIdentityPoolId () {
    return getAuthConfig(getEnvFromUrl()).IdentityPoolId
}

function getAuthConfig (env: string) {
    if (!(env in AUTH_CONFIGS)) {
        throw new Error(`Invalid environment: ${process.env.APP_ENV}, Supported environments: ${Object.keys(AUTH_CONFIGS)}`)
    }
    return Object.entries(AUTH_CONFIGS).find(([key, value]) => key === env)?.[1]
}

export function getWebSocketUrl () {
    return getAuthConfig(getEnvFromUrl()).WebSocketUrl
}

export function loadAccessInfo () {
    return new Promise((resolve) => {
        AWS.config.region = 'us-east-1'
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: getIdentityPoolId()
        });

        (AWS.config.credentials as AWS.CognitoIdentityCredentials).get(function () {
            const accessInfo = {
                access_key: (AWS.config.credentials as AWS.CognitoIdentityCredentials).accessKeyId,
                secret_key: (AWS.config.credentials as AWS.CognitoIdentityCredentials).secretAccessKey,
                session_token: (AWS.config.credentials as AWS.CognitoIdentityCredentials).sessionToken
            }
            resolve(accessInfo)
        })
    })
}
